import { Auth } from '@aws-amplify/auth'
import AuthFlow from '@/components/atoms/AuthFlow'
import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import InputField from '@/components/atoms/InputField'
import { withFooter } from '@/components/molecules/Footer'
import Account from '@/components/template/Account'
import { ERROR } from '@/constant/errors'
import { authApi } from '@/ghgApi'
import { ROUTES } from '@/routes'
import { checkAccessUser, signOut } from '@/services/auth'
import theme from '@/theme'
import cache from '@/utils/cache'
import translate from '@/utils/translations'
import { passwordValidate } from '@/utils/validate'
import { makeStyles } from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import React, { FormEvent, useEffect, useState } from 'react'

const TOTP_CODE_LENGTH: number = 6

const useStyle = makeStyles({
    login: {
        minHeight: `calc(100vh - 50px - ${theme.footer.footerHeight})`,
    },
    loginForm: { minWidth: 450, width: '100%' },
    formFields: { display: 'flex', flexDirection: 'column', rowGap: '25px' },
    forgotPasswordLink: {
        display: 'block',
        color: theme.colors.primaryN,
        textDecoration: 'underline',
        fontSize: 14,
        marginTop: 10,
    },
    errorText: {
        listStyle: 'none',
        fontWeight: 600,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
    },
    outlineBtn: {
        marginTop: 5,
    },
    listInstruction: {
        margin: 0,
        paddingLeft: 20,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    instructionText: {
        color: theme.colors.black,
        fontWeight: 'lighter',
        margin: 0,
        fontSize: 14,
    },
    mfaDescription: {
        color: '#222222',
        fontSize: '14px',
    },
    mfaCodeArea: {
        marginTop: '28px',
    },
    mfaCodeInput: {
        backgroundColor: theme.colors.white,
        borderRadius: 5,
        width: '200px',
        '&:hover': {
            backgroundColor: theme.colors.white,
        },
    },
})

function LoginInner() {
    const [errors, setErrors] = useState([] as Array<string>)
    const [showForm, setShowForm] = useState(false)
    const [showTotpCodeForm, setShowTotpCodeForm] = useState(false)
    const [user, setUser] = useState<any>()
    const [loading, setLoading] = useState(false)
    const classes = useStyle()

    useEffect(() => {
        let now = new Date()
        let endDate = new Date("2024-01-19 19:00:00")
        console.log("now", now)
        console.log("endDate", endDate)
        console.log(now < endDate)
        if (now < endDate) {
            navigate(ROUTES.MAINTENANCE)
            return
        }

        const checkAccess = async () => {
            const isAccess = await checkAccessUser()
            if (isAccess) navigate('/')
            else setShowForm(true)
        }
        checkAccess()
    }, [])

    const changePasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (errors.length) setErrors([])

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            password: HTMLInputElement
            password_confirm: HTMLInputElement
        }
        if (user) {
            const errors = passwordValidate(user.challengeParam.userAttributes.email, elements.password.value)
            if (errors.length) {
                setErrors(errors)
                return
            }
            setLoading(true)
            try {
                await await Auth.completeNewPassword(user, elements.password.value)
                const userInfo = await authApi.login({
                    email: user.challengeParam.userAttributes.email,
                    password: elements.password.value,
                })
                cache.set('access_token', userInfo.data.access_token)
                window.location.reload()
            } catch (err) {
                setLoading(false)
                console.log(err)
            }
        }
    }

    const signIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (errors.length) setErrors([])

        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            username: HTMLInputElement
            password: HTMLInputElement
        }
        setLoading(true)
        try {
            const user = await Auth.signIn(elements.username.value, elements.password.value)
            if (user) {
                // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setUser(user)
                    setLoading(false)
                    return
                } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    // 認証コード入力へ
                    setUser(user)
                    setShowTotpCodeForm(true)
                    setLoading(false)
                    return
                }
                const userInfo = await authApi.login({
                    email: elements.username.value,
                    password: elements.password.value,
                })
                cache.set('access_token', userInfo.data.access_token)
                window.location.reload()
            }
        } catch (err: any) {
            if (err) {
                setLoading(false)
                const translation = translate(err.response?.data?.errors[0]?.message || err?.message)
                if (translation) setErrors([translation])
                if (err.response) {
                    signOut()
                }
            }
        }
    }

    const handleChangeTotpCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const totpCode = e.target.value
        validateTotpCode(totpCode)
    }

    const validateTotpCode = (totpCode: string) => {
        const errors = [] as Array<string>
        if (!totpCode) {
            errors.push(ERROR.IS_REQUIRED)
        }
        if (!/^[0-9]*$/.test(totpCode)) {
            errors.push(ERROR.NON_NUMERIC_CHARACTERS)
        }
        if (totpCode.length != TOTP_CODE_LENGTH) {
            errors.push(`${TOTP_CODE_LENGTH}文字で入力してください。`)
        }
        setErrors(errors)
    }

    const totpCodeSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (errors.length) setErrors([])
        const form = e.currentTarget
        const elements = form.elements as typeof form.elements & {
            totpCode: HTMLInputElement
        }
        setLoading(true)
        try {
            if (!user) {
                console.log(`Failed to retrieve the cognitoUser."`)
                return
            }
            const totpCode = elements.totpCode.value
            if (!totpCode) {
                console.log(`TotpCode is empty.`)
                return
            }
            const confirmedUser = await Auth.confirmSignIn(user, totpCode, 'SOFTWARE_TOKEN_MFA')
            cache.set('access_token', confirmedUser.signInUserSession.accessToken.jwtToken)
            window.location.reload()
        } catch (err: any) {
            switch (err.code) {
                case "CodeMismatchException":
                    setErrors([`誤った認証コードです。または有効期限が切れています。`])
                    break
                default:
                    setErrors([`不明なエラーです。`])
            }
            setLoading(false)
            console.log(err)
        }
    }

    const LoginForm = () => (
        <form onSubmit={signIn} className={classes.loginForm}>
            <div className={classes.formFields}>
                <AuthFlow.FormField
                    id="login-form-username"
                    name="username"
                    type="email"
                    placeholder="メールアドレス"
                />

                <AuthFlow.PasswordField id="login-form-password" name="password" placeholder="パスワード" />
            </div>

            <Link className={classes.forgotPasswordLink} to={ROUTES.FORGOT_PASSWORD}>
                パスワードをお忘れの方はこちら
            </Link>

            <div>
                <AuthFlow.ErrorsList>
                    {Boolean(errors.length) &&
                        errors.map((error, index) => (
                            <li key={index} className={classes.errorText}>
                                <ErrorIcon />
                                &nbsp;
                                {error}
                            </li>
                        ))}
                </AuthFlow.ErrorsList>
            </div>
            <PrimaryButton disabled={loading} fullSize type="submit">
                ログイン
            </PrimaryButton>
        </form>
    )
    const ForceChangePassword = () => (
        <form onSubmit={changePasswordSubmit} className={classes.loginForm}>
            <AuthFlow.FlexCenter>
                <div>
                    <h4 className={classes.instructionText}>初回ログイン時にパスワードの変更をお願いしております。</h4>
                    <br />
                    <h4 className={classes.instructionText}>以下の条件を満たすパスワードを設定ください。</h4>
                    <ul className={classes.listInstruction}>
                        <li className={classes.instructionText}>
                            半角の英大文字、英小文字、数字をそれぞれ1文字以上含む
                        </li>
                        <li className={classes.instructionText}>12文字以上</li>
                        <li className={classes.instructionText}>メールアドレスと異なる</li>
                    </ul>
                </div>
            </AuthFlow.FlexCenter>
            <div style={{ marginTop: 20 }}>
                <AuthFlow.PasswordField id="password" name="password" placeholder="新しいパスワード" />
            </div>
            <div>
                <AuthFlow.ErrorsList>
                    {Boolean(errors.length) &&
                        errors.map((error, index) => (
                            <li key={index} className={classes.errorText}>
                                <ErrorIcon />
                                &nbsp;
                                {error}
                            </li>
                        ))}
                </AuthFlow.ErrorsList>
            </div>
            <PrimaryButton disabled={loading} fullSize type="submit" style={{ marginTop: 15 }}>
                変更
            </PrimaryButton>
            <OutlineButton
                fullSize
                className={classes.outlineBtn}
                onClick={() => {
                    setErrors([])
                    setUser(undefined)
                }}
            >
                戻る
            </OutlineButton>
        </form>
    )

    if (!showForm) return null

    return (
        <div className={classes.login}>
            <Account subtitile={showTotpCodeForm ? '多要素認証' : user ? 'ログインパスワード変更' : 'ログイン'}>
                <title>ログイン｜ScopeX</title>
                {
                    showTotpCodeForm 
                    ? 
                        <form onSubmit={totpCodeSubmit} className={classes.loginForm}>
                            <div className={classes.mfaDescription}>
                                MFAデバイスに表示されている6桁の数字を入力してください。
                            </div>
                            <AuthFlow.FlexCenter className={classes.mfaCodeArea}>
                                <InputField
                                    name="totpCode"
                                    className={classes.mfaCodeInput}
                                    placeholder="認証コード"
                                    onChange={handleChangeTotpCode}
                                />
                            </AuthFlow.FlexCenter>
                            <div>
                                <AuthFlow.ErrorsList>
                                    {Boolean(errors.length) &&
                                        errors.map((error, index) => (
                                            <li key={index} className={classes.errorText}>
                                                <ErrorIcon />
                                                &nbsp;
                                                {error}
                                            </li>
                                        ))}
                                </AuthFlow.ErrorsList>
                            </div>
                            <PrimaryButton
                                type="submit"
                                style={{ marginTop: 15 }}
                                fullSize
                                disabled={!!errors.length || loading}
                            >
                                認証
                            </PrimaryButton>
                        </form>
                    : 
                        user ? <ForceChangePassword /> : <LoginForm />}
            </Account>
        </div>
    )
}
const Login = () => <LoginInner />
export default withFooter(Login)
