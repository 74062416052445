import { KEY_LOCALSTORAGE } from '@/constant/text'
import { ROUTES } from '@/routes'
import cache from '@/utils/cache'
import Auth from '@aws-amplify/auth'
import { navigate } from 'gatsby'

export const signOut = async () => {
    try {
        await Auth.signOut({ global: true })
        cache.remove('access_token')
        cache.remove(KEY_LOCALSTORAGE.anti_warming)
        cache.remove(KEY_LOCALSTORAGE.energy_saving)
        navigate(ROUTES.LOGIN)
    } catch (err) {
        console.warn('error signing out', err)
    }
}

export const checkAccessUser = async () => {
    try {
        const res = await Auth.currentAuthenticatedUser()
        const token = cache.get('access_token')
        if (res && token) return true
        return false
    } catch (err) {
        return false
    }
}
